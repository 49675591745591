import React, { useEffect, useState } from 'react';
import { GlobalTypes, useGlobalState } from '../../../../src/contexts/global';
import ReactDOM from 'react-dom';
import { classes } from '../../../services/utils';
import './Notification.scss';

interface INotification {}

export type NotificationStyle = 'error' | 'success';

let interval: NodeJS.Timeout;
const time = 6;
let prevText = '';

const Notification: React.FC<INotification> = () => {
    const { state, dispatch } = useGlobalState();
    const { text, style } = state.notification;
    const [counter, setCounter] = useState(0);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        counter <= 0 && closeNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    function closeNotification() {
        setCounter(0);
        setHover(false);
        dispatch({ type: GlobalTypes.ToggleNotification });
    }

    useEffect(() => {
        if (text) {
            setCounter(prevText !== text ? time : counter);
            interval = setInterval(() => {
                setCounter((count) => {
                    if (hover) return counter;
                    return count - 1;
                });
            }, 1000);
        }
        return () => {
            setCounter(0);
            clearInterval(interval);
            prevText = text;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, hover]);

    return text
        ? ReactDOM.createPortal(
              <div
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  className={classes('notification', {
                      'is-error': style === 'error',
                      'is-success': style === 'success',
                  })}
              >
                  <div>{text}</div>
                  <button onClick={closeNotification} title="Zavřít" className="notification-close">
                      &#10005;
                  </button>
                  <span className="notification-progress" style={{ width: `${(counter / time) * 100}%` }} />
              </div>,
              document.getElementById('notification-root')!,
          )
        : null;
};

export default Notification;
